
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function CategoryPageRT () {
    return _createElement(_Fragment, {}, [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'btn btn-default btn-inverse  cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '740'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8150'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', { 'className': 'product-item' }, _createElement('div', { 'className': 'product-item-inner' }, _createElement('div', { 'className': 'img' }, _createElement('a', { 'href': this.ProductLink }, _createElement('img', {
                            'src': this.imageOrDefault(this.ThumbnailFile),
                            'alt': this.removeHTML(this.Name),
                            'onError': this.onImageError
                        })), window.isQuickviewEnabled() ? _createElement('button', {
                            'className': 'quickview',
                            'data-toggle': 'modal',
                            'key': '265'
                        }, 'Quick View') : null), _createElement('div', { 'className': 'product-content' }, _createElement('div', { 'className': 'name' }, _createElement('a', Object.assign({}, { 'href': this.ProductLink }, { dangerouslySetInnerHTML: { __html: this.Name } }))), _createElement('div', { 'className': 'status' }, _createElement('span', { 'className': 'availability' }, this.AvailabilityMessage)), _createElement('div', { 'className': 'reviews' }, _createElement('span', { 'className': 'reviews-stars rating-' + this.ReviewAverage })), this.Price ? _createElement('div', {
                            'className': 'price',
                            'key': '770'
                        }, _createElement('span', { 'className': this.OnSale ? 'sale-price' : 'regular-price' }, this.formatPrice(this.Price)), this.OnSale ? _createElement('span', {
                            'className': 'on-sale-badge',
                            'key': '944'
                        }, 'Sale') : null) : null, _createElement('div', { 'className': 'action' }, _createElement('a', {
                            'href': this.ProductLink,
                            'className': 'btn btn-default'
                        }, _createElement('span', { 'className': 'buyitlink-text' }, 'View Details'))))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_Results cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'clear' }));
}
        export const componentNames = ["cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:SearchResult"]