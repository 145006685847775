const pageTypeToFieldMap = {
  category: 'Category',
  brand: 'ManufacturerID',
};

function getLocalPreselection(pageType, defaults) {
  const field = pageTypeToFieldMap[pageType];
  if (field) {
    const term = pageType === 'category' ? defaults.termFromBreadcrumbs : `${window.cmCurrentManId}`;
    if (term) {
      return [{ field, term }];
    }
  }
}

const ajustItemsNameHeight = () => {
  const itemNames = [...document.querySelectorAll('.product-items .product-item .name')];
  const maxHeight = itemNames.reduce((max, itemName) => Math.max(itemName.scrollHeight, max), 0);
  itemNames.forEach((item) => (item.style.height = `${maxHeight}px`));
};

const HideResults = () => {
  const request = window.Convermax.getSearchRequest();
  window
    .$('section#category, section#searchGroup')
    [
      request.selection.length > 0 || request.query.length > 0 ? 'addClass' : 'removeClass'
    ]('cm_hideDefaultResults');
};

const searchResultsUpdateCallback = () => {
  window.closeParentModal();
  HideResults();
  ajustItemsNameHeight();
};

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  SearchRequestDefaults: {
    pageSize: 200,
  },
  product: {
    noImageSrc: '/assets/images/default.jpg',
  },
  facets: {
    rangedFacet: { fields: ['Price'], name: 'priceFacet' },
  },
  getLocalPreselection,
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
      },
    },
    {
      name: 'SearchResult',
      updateCallback: searchResultsUpdateCallback,
    },
    {
      name: 'FacetPanel',
    },
    {
      name: 'FacetPanelCategory',
      type: 'FacetPanel',
      location: { lastChildOf: '#cm_categoryChipsNFacets', class: 'cm_facet-panel_container' },
      visibleIf: () => !document.body.classList.contains('cm_disable-filters'),
      template: 'FacetPanelContainer',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm_categorySearchPage',
      visibleIf: () => !document.body.classList.contains('cm_disable-filters'),
      template: 'CategoryPage',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: '.site-header #searchBox', id: 'searchBox' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
  ],
};
