import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import TemplateCategoryPage from 'Stores/alligatorperformance/Templates/CategoryPage.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'

export const compMap = {
  SearchPage,
SearchHeader,
SearchResult,
FacetPanel,
SearchBoxDialogButton,
SearchBoxDialog,
FacetDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'FacetPanelContainer': TemplateFacetPanelContainer,
'CategoryPage': TemplateCategoryPage,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'SearchBoxDialog': TemplateSearchBoxDialog,
'FacetDialog': TemplateFacetDialog
};